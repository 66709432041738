import { useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const myScript = useScript({"key":"myScript","src":"https://cloud.umami.is/script.js","defer":true,"data-website-id":"5ab539d5-b148-4c06-9ec3-59d98ca7d246"}, { use: () => ({ myScript: window.myScript }) })
    return { provide: { $scripts: { myScript } } }
  }
})