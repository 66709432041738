let _initialized = false
export function init(addIcon) {
  if (_initialized)
    return
  const collections = JSON.parse("[{\"prefix\":\"tabler\",\"icons\":{\"arrow-back-up\":{\"width\":24,\"height\":24,\"body\":\"<g fill=\\\"none\\\" stroke=\\\"currentColor\\\" stroke-linecap=\\\"round\\\" stroke-linejoin=\\\"round\\\" stroke-width=\\\"2\\\"><path d=\\\"m9 14l-4-4l4-4\\\"/><path d=\\\"M5 10h11a4 4 0 1 1 0 8h-1\\\"/></g>\"},\"arrow-right\":{\"width\":24,\"height\":24,\"body\":\"<path fill=\\\"none\\\" stroke=\\\"currentColor\\\" stroke-linecap=\\\"round\\\" stroke-linejoin=\\\"round\\\" stroke-width=\\\"2\\\" d=\\\"M5 12h14m-6 6l6-6m-6-6l6 6\\\"/>\"},\"pencil\":{\"width\":24,\"height\":24,\"body\":\"<path fill=\\\"none\\\" stroke=\\\"currentColor\\\" stroke-linecap=\\\"round\\\" stroke-linejoin=\\\"round\\\" stroke-width=\\\"2\\\" d=\\\"M4 20h4L18.5 9.5a2.828 2.828 0 1 0-4-4L4 16zm9.5-13.5l4 4\\\"/>\"}}}]")
  for (const collection of collections) {
    for (const [name, data] of Object.entries(collection.icons)) {
      addIcon(collection.prefix ? (collection.prefix + ':' + name) : name, data)
    }
  }
  _initialized = true
}