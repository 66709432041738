import revive_payload_client_Wllj18zJZ5 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.8.0_ioredis@5.4.1_optionator@0._vqsvtwkftdpzzxrihuiyxl6lsi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_e63VJQFesQ from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.8.0_ioredis@5.4.1_optionator@0._vqsvtwkftdpzzxrihuiyxl6lsi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Cb2hHt5637 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.8.0_ioredis@5.4.1_optionator@0._vqsvtwkftdpzzxrihuiyxl6lsi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0mzYmscOYq from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.21_rollup@4.19.1_vite@5.3.5_@types+node@22.0.0_sass@1.77.8_terser@5.31.3_uo5jhcl5qckdy5hpoqvnuhbpcu/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_15IkhK91r4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.8.0_ioredis@5.4.1_optionator@0._vqsvtwkftdpzzxrihuiyxl6lsi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_EEMiWswwAF from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.8.0_ioredis@5.4.1_optionator@0._vqsvtwkftdpzzxrihuiyxl6lsi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_924cwOkJNe from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.8.0_ioredis@5.4.1_optionator@0._vqsvtwkftdpzzxrihuiyxl6lsi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_5fCpsYHJgi from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.8.0_ioredis@5.4.1_optionator@0._vqsvtwkftdpzzxrihuiyxl6lsi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _64nuxt_scripts_D34jXE4xNh from "/vercel/path0/.nuxt/modules/@nuxt-scripts.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_BGopPnGUGf from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.8.0_ioredis@5.4.1_optionator@0._vqsvtwkftdpzzxrihuiyxl6lsi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/vercel/path0/.nuxt/floating-vue.mjs";
import plugin_AeCpwNCY0I from "/vercel/path0/node_modules/.pnpm/@twicpics+components@0.29.3/node_modules/@twicpics/components/nuxt3/plugin.js";
import plugin_grK2vP0aIH from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.7.4_rollup@4.19.1_vite@5.3.5_@types+node@22.0.0_sass@1.77.8_terser@5.31.3__vue@3.4.34_typescript@5.5.3_/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import posthog_client_fUucxKWhZE from "/vercel/path0/plugins/posthog.client.ts";
export default [
  revive_payload_client_Wllj18zJZ5,
  unhead_e63VJQFesQ,
  router_Cb2hHt5637,
  _0_siteConfig_0mzYmscOYq,
  payload_client_15IkhK91r4,
  navigation_repaint_client_EEMiWswwAF,
  check_outdated_build_client_924cwOkJNe,
  chunk_reload_client_5fCpsYHJgi,
  _64nuxt_scripts_D34jXE4xNh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_BGopPnGUGf,
  floating_vue_EIcJ7xiw0h,
  plugin_AeCpwNCY0I,
  plugin_grK2vP0aIH,
  posthog_client_fUucxKWhZE
]