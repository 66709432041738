import { default as _91slug_93Po03rnzIFtMeta } from "/vercel/path0/pages/blog/[slug].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as projectscSYnWgH6LrMeta } from "/vercel/path0/pages/projects.vue?macro=true";
import { default as component_45stubxuzvoAWUewMeta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.8.0_ioredis@5.4.1_optionator@0._vqsvtwkftdpzzxrihuiyxl6lsi/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubxuzvoAWUew } from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.8.0_ioredis@5.4.1_optionator@0._vqsvtwkftdpzzxrihuiyxl6lsi/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/vercel/path0/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("/vercel/path0/pages/projects.vue").then(m => m.default || m)
  },
  {
    name: component_45stubxuzvoAWUewMeta?.name,
    path: "/articles",
    component: component_45stubxuzvoAWUew
  },
  {
    name: component_45stubxuzvoAWUewMeta?.name,
    path: "/articles/:pathMatch(.*)",
    component: component_45stubxuzvoAWUew
  },
  {
    name: component_45stubxuzvoAWUewMeta?.name,
    path: "/blog/repository-pattern-in-composables",
    component: component_45stubxuzvoAWUew
  }
]